
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppPlaceOfCostIndex} from "@/models/app/place-of-cost";
import {ResponseIndex} from "@/models/response";
import {PaginationIndex} from "@/models/pagination";

// Components
import {
  ElPagination,
} from "element-plus";
import AppAdministratorMasterDataPlaceOfCostsListFormFilterIndexVue from "@/views/app/administrator/master-data/place-of-costs/list/form/filter.vue";
import AppAdministratorMasterDataPlaceOfCostsListFormCreateIndexVue from "@/views/app/administrator/master-data/place-of-costs/list/form/create.vue";
import AppAdministratorMasterDataPlaceOfCostsListFormUpdateIndexVue from "@/views/app/administrator/master-data/place-of-costs/list/form/update.vue";
import AppAdministratorMasterDataPlaceOfCostsListFormRemoveIndexVue from "@/views/app/administrator/master-data/place-of-costs/list/form/remove.vue";
import LoadingIndexVue from "@/components/loading.vue";

// Services
import {getRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElPagination,
    AppAdministratorMasterDataPlaceOfCostsListFormFilterIndexVue,
    AppAdministratorMasterDataPlaceOfCostsListFormCreateIndexVue,
    AppAdministratorMasterDataPlaceOfCostsListFormUpdateIndexVue,
    AppAdministratorMasterDataPlaceOfCostsListFormRemoveIndexVue,
    LoadingIndexVue,
  },
})
export default class AppAdministratorMasterDataPlaceOfCostsListIndexVue extends Vue {
  isLoading = false;

  searchQuery = "*";
  pagination: PaginationIndex = new PaginationIndex();

  placeOfCosts: Array<AppPlaceOfCostIndex> = [];

  get isDataLoaded(): boolean {
    return this.placeOfCosts.length > 0 && this.isLoading === false;
  }

  get isDataLoadedAndEmpty(): boolean {
    return this.placeOfCosts.length === 0 && this.isLoading === false;
  }

  get isPaginationVisible(): boolean {
    return this.pagination.last_page > 1 && this.isLoading === false;
  }

  get translation(): any {
    return getTranslation([
      "placeOfCosts",
      "noData",
    ]);
  }

  async getPlaceOfCosts(searchQuery = "*", page = 1): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/place-of-costs`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        searchQuery: this.searchQuery = searchQuery,
        page: this.pagination.current_page = page,
        pagination: true,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.placeOfCosts = r.data.data;
        this.pagination = {
          current_page: r.data.current_page,
          first_page_url: r.data.first_page_url,
          from: r.data.from,
          last_page: r.data.last_page,
          last_page_url: r.data.last_page_url,
          next_page_url: r.data.next_page_url,
          path: r.data.path,
          per_page: r.data.per_page,
          prev_page_url: r.data.prev_page_url,
          to: r.data.to,
          total: r.data.total,
        };
      }
    });
    this.isLoading = false;
  }

  setPagination(page): void {
    this.getPlaceOfCosts(this.searchQuery, page);
  }

  async created(): Promise<void> {
    await this.getPlaceOfCosts();
  }
}

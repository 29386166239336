
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppPlaceOfCostCreateIndex} from "@/models/app/place-of-cost/create";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElButton,
} from "element-plus";
import {
  Plus,
} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
    Plus,
  },
})
export default class AppAdministratorMasterDataPlaceOfCostsListFormCreateIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;

  formData: AppPlaceOfCostCreateIndex = new AppPlaceOfCostCreateIndex();
  formDataRules = {
    content: {
      title: [
        {
          required: true,
          trigger: "blur",
        },
      ],
    },
  };

  get translation(): any {
    return getTranslation([
      "add",
      "title",
      "titlePlaceholderPlaceOfCosts",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/common/place-of-cost/create",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getPlaceOfCosts');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  resetFormData(): void {
    this.formData = new AppPlaceOfCostCreateIndex();
  }
}

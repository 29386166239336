import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0961f720"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-master-data-place-of-costs-form-update" }
const _hoisted_2 = { class: "app-administrator-master-data-place-of-costs-form-update__action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Edit = _resolveComponent("Edit")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isDialogVisible = true), ["prevent"]))
      }, [
        _createVNode(_component_Edit)
      ])
    ]),
    _createVNode(_component_el_dialog, {
      title: _ctx.translation.edit,
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          class: "el-form--default",
          model: _ctx.formData,
          rules: _ctx.formDataRules,
          ref: "formComponent",
          onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.title,
              prop: "content.title"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.formData.content.title,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.content.title) = $event)),
                  placeholder: _ctx.translation.titlePlaceholderPlaceOfCosts
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  class: "el-button--primary el-button--block",
                  "native-type": "submit",
                  loading: _ctx.isLoading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translation.save), 1)
                  ]),
                  _: 1
                }, 8, ["loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "onSubmit"])
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}